'use client'

import React from 'react'
import useTranslation from 'app/i18n/client'
import TrackClick from 'app/components/TrackClick/TrackClick.client'
import MadlibSection from 'app/components/Madlib/Madlib.client'
import useMadlibSubmittedObject from 'app/components/Madlib/MadlibSubmittedObject.client'
import TrackImpression from 'app/components/TrackImpression/TrackImpression.client'
import useGlobalContext from '../GlobalContext/useGlobalContext'
import { IMadlibSelect } from '../Madlib/Madlib.interface'

interface INotFoundMadlibProps {
  button: {
    cta: string
    label: string
  }
  madlib: {
    saveButtonLabel: string
    select: IMadlibSelect[]
    selectorBackgroundColor?: string
    snowplow: {
      unitVariation: string
    }
  }
}

function NotFoundMadlibComponent({
  button,
  madlib
}: INotFoundMadlibProps) {
  const { t } = useTranslation()
  const { locale } = useGlobalContext()
  const submittedObject = useMadlibSubmittedObject(madlib.select)

  return (
    <>
      <h1 className='f-sm-7 f-md-9 pb-sm-6 text-center'>
        <span className='text-foreground-scarlet'>{t('oops')} </span>
        {t('not-found-heading')}
      </h1>
      <p className='f-sm-5 pb-sm-6 text-center'>{t('error-page-subtitle')}</p>
      <TrackImpression
        unitName='madlib'
        buttonName={null}
        unitLocation='inline'
        as='fragment'
        unitVariation={madlib.snowplow.unitVariation}
        componentId={null}
      />

      <div
        className='f-sm-6 f-md-8 text-center lh-default'
        style={{ maxWidth: 920, margin: 'auto' }}
      >
        <MadlibSection
          select={madlib.select}
          saveButtonLabel={madlib.saveButtonLabel}
          selectorBackgroundColor={madlib.selectorBackgroundColor}
          snowplow={madlib.snowplow}
        />
      </div>

      <div className='mt-sm-5 mt-md-6'>
        <div className='my-sm-5 text-center'>
          <TrackClick
            className='Btn Btn--shadow Btn--responsive no-underline d-sm-inline-block'
            href={button.cta}
            locale={locale === 'es-mx' ? 'es-mx' : 'en'}
            data-testid='madlib-cta'
            isEngagementEvent
            trackProps={{
              eventType: 'select',
              submittedObject,
              unitLocation: 'inline',
              unitName: 'madlib',
              linkedContentUrl: '/hub',
              linkedContentId: '6gSZLoY2zMNeHtgz5PoiMi',
              unitVariation: madlib.snowplow.unitVariation,
              componentId: null
            }}
          >
            {button.label}
          </TrackClick>
        </div>
      </div>
    </>
  )
}

export default NotFoundMadlibComponent
