'use client'

import React from 'react'
import PreferencesContextProvider from '@common/components/UsePreferencesContext'
import ContentfulLazyLoad from 'app/components/ContentfulLazyLoad/ContentfulLazyLoad'
import NotFoundMadlibComponent from './NotFoundMadlibComponent.client'
import useGlobalContext from '../GlobalContext/useGlobalContext'

function NotFoundMadlib() {
  const { locale } = useGlobalContext()

  return (
    <div className='container grid grid-gap flex-sm-align-center' style={{ minHeight: '90dvh' }}>
      <div className='grid-col-sm-12 grid-col-lg-10 grid-start-lg-2'>
        <PreferencesContextProvider locale={locale === 'es-mx' ? 'es-mx' : 'en'}>
          <ContentfulLazyLoad slug='article-madlib-section'>
            {componentData => (
              <NotFoundMadlibComponent
                button={componentData.button}
                madlib={componentData.madlib}
              />
            )}
          </ContentfulLazyLoad>
        </PreferencesContextProvider>
      </div>
    </div>
  )
}

export default NotFoundMadlib
